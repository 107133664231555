import { VCard } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    { staticClass: "v-card-custom" },
    [
      _c(
        VLayout,
        { attrs: { row: "", wrap: "", "fill-height": "" } },
        [
          _c(
            VFlex,
            { attrs: { xs12: "" } },
            [
              _c(
                VLayout,
                { attrs: { row: "", wrap: "" } },
                [
                  _c(VFlex, { attrs: { xs12: "" } }, [
                    _c("div", { staticClass: "H5-Primary-Left" }, [
                      _vm._v("CentricOS Setup")
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            VFlex,
            { attrs: { xs12: "" } },
            [
              _c(
                VLayout,
                { attrs: { "mt-1": "", xs12: "" } },
                [
                  _c(
                    VFlex,
                    { staticStyle: { "flex-direction": "column" } },
                    [
                      _c(
                        "div",
                        { staticClass: "Body-1-Black-High-Emphasis-Center " },
                        [
                          _c("span", [
                            _vm._v(
                              "Centric Menus can now be assigned to a Station from within Centric OS."
                            )
                          ]),
                          _c("br"),
                          _c("br"),
                          _c("span", [
                            _vm._v("Once a "),
                            _c("b", [_vm._v("Local Menu Group")]),
                            _vm._v(
                              " is mapped to a site, those brands/menus are\n              available in Menu Scheduling."
                            )
                          ])
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }